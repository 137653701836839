<template>
    <div>
        <v-row 
            v-if="projectSelected != null && projectSelected != undefined"
            justify="center"
        >
            <canvas id="canvas" hidden></canvas>
            
            <v-expansion-panels
                v-model="panel"
                multiple
            >
                <!-- ENGINEERING NOTES -->
                <v-col
                    v-if="showEngineeringNotes"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-notebook-edit-outline
                                    </v-icon>
                                    Engineering Notes
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="engineeringNotes"
                                        rows="4"
                                        row-height="23"
                                        auto-grow
                                        outlined
                                        disabled
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    v-if="showEngineeringNotes"
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon color="warning">
                        mdi-alert
                    </v-icon>
                </v-col>

                <!-- ATTACHMENTS -->
                <v-col
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-information-variant
                                    </v-icon>
                                    {{ titles.details }}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col
                                    cols="12"
                                    lg="4"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Survey Type</label>
                                    <v-combobox
                                        v-model="serviceTechSpecRequest.surveyTypeSelected"
                                        :rules=[validations.required]
                                        :items="listSurveyType"
                                        item-text="description"
                                        item-value="id"
                                        clearable
                                        outlined
                                        dense
                                    ></v-combobox>
                                </v-col>
                                <v-col
                                    cols="12"
                                    lg="4"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Survey (PDF)</label>
                                    <v-file-input
                                        v-model="surveyFile" 
                                        :rules=[validations.file]
                                        hint=" "
                                        persistent-hint
                                        accept=".pdf"
                                        small-chips
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        dense
                                    ></v-file-input>
                                </v-col>

                                <v-col
                                    cols="12"
                                    lg="4"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Architectural Site Plan  (PDF, CAD, DWG)</label>
                                    <v-file-input
                                        v-model="architecturalSitePlanFile" 
                                        accept=".pdf,.cad,.dwg"
                                        small-chips
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        dense
                                    ></v-file-input>
                                </v-col>

                                <v-col
                                    cols="12"
                                    lg="4"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Pool Studio File (DXF)</label>
                                    <v-file-input
                                        v-model="poolStudioDXFFile" 
                                        :rules=[validations.file]
                                        accept=".dxf"
                                        small-chips
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        dense
                                    ></v-file-input>
                                </v-col>

                                <v-col
                                    cols="12"
                                    lg="8"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Pool Studio File (PDF)</label>
                                    <v-file-input
                                        v-model="poolStudioPDFFile" 
                                        :rules=[validations.file]
                                        accept=".pdf"
                                        small-chips
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        dense
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <label>Pool Studio Images</label>
                                    <v-file-input
                                        v-model="poolStudioFilesPhotos" 
                                        accept="image/*"
                                        small-chips
                                        multiple
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        @change="resizePreviewImage(poolStudioFilesPhotos, poolStudioPhotos)"
                                        dense
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    v-for="(itemPhoto, index) in poolStudioPhotos.filter(pht => pht.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="2"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card
                                        @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                    >
                                        <v-img
                                            :src="itemPhoto.src"
                                            :lazy-src="itemPhoto.src"
                                            aspect-ratio="1"
                                            max-height="150"
                                            max-width="150"
                                        >
                                            <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                            </template>
                                        </v-img>
                                        <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                        <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(poolStudioPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionDetails" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.detailsType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.detailsType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.details, serviceDraftingRequest.detailsNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

                <!-- EQUIPMENT LOCATION -->
                <v-col
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-arrow-split-vertical
                                    </v-icon>
                                    {{ titles.dimensionedEquipmentLocation }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-tooltip 
                                        v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.dimensionedEquipmentLocationNeedsApproval == 1"
                                        left
                                    >
                                        <template v-slot:activator="{ on }">

                                            <v-btn  
                                                v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.dimensionedEquipmentLocationNeedsApproval == 1"
                                                class="mx-2 btnAlert"
                                                v-on="on"
                                                @click.stop=""
                                                @click="showApproveOrDenyYesNoAndNotes(titles.dimensionedEquipmentLocation, serviceTechSpecRequest.dimensionedEquipmentLocationNewValue, serviceTechSpecRequest.dimensionedEquipmentLocationNotesNewValue, dimensionedEquipmentLocationPhotos)"
                                            >
                                                Review pending changes
                                            </v-btn>
                                        </template>
                                        <span>Approve or Deny</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Already composed in the Pool Studio?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.dimensionedEquipmentLocation" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.dimensionedEquipmentLocation == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="dimensionedEquipmentLocationFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(dimensionedEquipmentLocationFilesPhotos, dimensionedEquipmentLocationPhotos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in dimensionedEquipmentLocationPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon"  small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(dimensionedEquipmentLocationPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.dimensionedEquipmentLocationNotes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionDimensionedEquipmentLocation" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.dimensionedEquipmentLocationType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.dimensionedEquipmentLocationType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.dimensionedEquipmentLocation, serviceDraftingRequest.dimensionedEquipmentLocationNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

                <!-- POOL BARRIER -->
                <v-col
                    v-if="isPool == true"
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left style="margin-top: -6px;">
                                        mdi-fence
                                    </v-icon>
                                    {{ titles.fenceEnclosureInformation }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-tooltip 
                                        v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.fenceEnclosureInformationNeedsApproval == 1"
                                        left
                                    >
                                        <template v-slot:activator="{ on }">

                                            <v-btn  
                                                v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.fenceEnclosureInformationNeedsApproval == 1"
                                                class="mx-2 btnAlert"
                                                v-on="on"
                                                @click.stop=""
                                                @click="showApproveOrDenyYesNoAndNotes(titles.fenceEnclosureInformation, serviceTechSpecRequest.fenceEnclosureInformationNewValue, serviceTechSpecRequest.fenceEnclosureInformationNotesNewValue, [])"
                                            >
                                                Review pending changes
                                            </v-btn>
                                        </template>
                                        <span>Approve or Deny</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <label>Fence Type</label>
                                    <v-combobox
                                        v-model="fenceTypeSelected"
                                        :rules=[validations.required]
                                        :items="listFenceType"
                                        item-text="description"
                                        item-value="id"
                                        chips
                                        multiple
                                        clearable
                                        outlined
                                        dense
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    v-if="isPool == true"
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionFenceEnclosureInformation" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.fenceEnclosureInformationType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.fenceEnclosureInformationType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.fenceEnclosureInformation, serviceDraftingRequest.fenceEnclosureInformationNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

                <!-- FENCE LOCATION -->
                <v-col
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-fence-electric
                                    </v-icon>
                                    {{ titles.fenceLocation }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-tooltip 
                                        v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.fenceLocationNeedsApproval == 1"
                                        left
                                    >
                                        <template v-slot:activator="{ on }">

                                            <v-btn  
                                                v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.fenceLocationNeedsApproval == 1"
                                                class="mx-2 btnAlert"
                                                v-on="on"
                                                @click.stop=""
                                                @click="showApproveOrDenyYesNoAndNotes(titles.fenceLocation, serviceTechSpecRequest.fenceLocationNewValue, serviceTechSpecRequest.fenceLocationNotesNewValue, fenceLocationPhotos)"
                                            >
                                                Review pending changes
                                            </v-btn>
                                        </template>
                                        <span>Approve or Deny</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Already composed in the Pool Studio?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.fenceLocation" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            
                            <v-row v-if="serviceTechSpecRequest.fenceLocation == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="fenceLocationFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(fenceLocationFilesPhotos, fenceLocationPhotos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in fenceLocationPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(fenceLocationPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.fenceLocationNotes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionFenceLocation" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.fenceLocationType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.fenceLocationType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.fenceLocation, serviceDraftingRequest.fenceLocationNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

                <!-- DEPTH AND DEEPEST POINT DESIRED LOCATION -->
                <v-col
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-graph-outline
                                    </v-icon>
                                    {{ titles.depthDeepestPointLocation }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-tooltip 
                                        v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.depthDeepestPointLocationNeedsApproval == 1"
                                        left
                                    >
                                        <template v-slot:activator="{ on }">

                                            <v-btn  
                                                v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.depthDeepestPointLocationNeedsApproval == 1"
                                                class="mx-2 btnAlert"
                                                v-on="on"
                                                @click.stop=""
                                                @click="showApproveOrDenyYesNoAndNotes(titles.depthDeepestPointLocation, serviceTechSpecRequest.depthDeepestPointLocationNewValue, serviceTechSpecRequest.depthDeepestPointLocationNotesNewValue, depthDeepestPointLocationPhotos)"
                                            >
                                                Review pending changes
                                            </v-btn>
                                        </template>
                                        <span>Approve or Deny</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Already composed in the Pool Studio?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.depthDeepestPointLocation" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            
                            <v-row v-if="serviceTechSpecRequest.depthDeepestPointLocation == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="depthDeepestPointLocationFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(depthDeepestPointLocationFilesPhotos, depthDeepestPointLocationPhotos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in depthDeepestPointLocationPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(depthDeepestPointLocationPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.depthDeepestPointLocationNotes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionDepthDeepestPointLocation" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.depthDeepestPointLocationType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.depthDeepestPointLocationType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.depthDeepestPointLocation, serviceDraftingRequest.depthDeepestPointLocationNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

                <!-- LIGHT LOCATION -->
                <v-col
                    v-if="isPool == true"
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-lightbulb-question-outline
                                    </v-icon>
                                    {{ titles.lightLocations }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-tooltip 
                                        v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.lightLocationsNeedsApproval == 1"
                                        left
                                    >
                                        <template v-slot:activator="{ on }">

                                            <v-btn  
                                                v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.lightLocationsNeedsApproval == 1"
                                                class="mx-2 btnAlert"
                                                v-on="on"
                                                @click.stop=""
                                                @click="showApproveOrDenyYesNoAndNotes(titles.lightLocations, serviceTechSpecRequest.lightLocationsNewValue, serviceTechSpecRequest.lightLocationsNotesNewValue, lightLocationsPhotos)"
                                            >
                                                Review pending changes
                                            </v-btn>
                                        </template>
                                        <span>Approve or Deny</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Already composed in the Pool Studio?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.lightLocations" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            
                            <v-row v-if="serviceTechSpecRequest.lightLocations == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="lightLocationsFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(lightLocationsFilesPhotos, lightLocationsPhotos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in lightLocationsPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(lightLocationsPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.lightLocationsNotes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    v-if="isPool == true"
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionLightLocation" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.lightLocationsType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.lightLocationsType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.lightLocations, serviceDraftingRequest.lightLocationsNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

                <!-- SOIL TEST -->
                <v-col
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-floor-plan
                                    </v-icon>
                                    {{ titles.soilTest }}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Applicable?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.soilTest" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.soilTest == 1">
                                <v-col
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <label>Documents (PDF)</label>
                                    <v-file-input
                                        v-model="soilTestFile" 
                                        :rules=[validations.file]
                                        accept=".pdf"
                                        small-chips
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        dense
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionSoilTest" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.soilTestType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.soilTestType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.soilTest, serviceDraftingRequest.soilTestNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

                <!-- WATER FEATURES -->
                <v-col
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-pool
                                    </v-icon>
                                    {{ titles.waterFeatures }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-tooltip 
                                        v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.waterFeaturesNeedsApproval == 1"
                                        left
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn  
                                                v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.waterFeaturesNeedsApproval == 1"
                                                class="mx-2 btnAlert"
                                                v-on="on"
                                                @click.stop=""
                                                @click="showApproveOrDenyYesNoAndNotes(titles.waterFeatures, serviceTechSpecRequest.waterFeaturesNewValue, serviceTechSpecRequest.waterFeaturesNotesNewValue, waterFeaturesPhotos)"
                                            >
                                                Review pending changes
                                            </v-btn>
                                        </template>
                                        <span>Approve or Deny</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Already composed in the Pool Studio?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.waterFeatures" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            
                            <v-row v-if="serviceTechSpecRequest.waterFeatures == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="waterFeaturesFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(waterFeaturesFilesPhotos, waterFeaturesPhotos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in waterFeaturesPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon"  small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(waterFeaturesPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.waterFeaturesNotes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionWaterFeatures" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.waterFeaturesType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.waterFeaturesType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.waterFeatures, serviceDraftingRequest.waterFeaturesNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

                <!-- PERGOLAS -->
                <v-col
                    v-if="isPergola == true"
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-home-edit-outline
                                    </v-icon>
                                    {{ titles.pergolas }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-tooltip 
                                        v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.pergolasNeedsApproval == 1"
                                        left
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn  
                                                v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.pergolasNeedsApproval == 1"
                                                class="mx-2 btnAlert"
                                                v-on="on"
                                                @click.stop=""
                                                @click="showApproveOrDenyYesNoAndNotes(titles.pergolas, serviceTechSpecRequest.pergolasNewValue, serviceTechSpecRequest.pergolasNotesNewValue, pergolasPhotos)"
                                            >
                                                Review pending changes
                                            </v-btn>
                                        </template>
                                        <span>Approve or Deny</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Already composed in the Pool Studio?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.pergolas" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.pergolas == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="pergolasFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(pergolasFilesPhotos, pergolasPhotos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in pergolasPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(pergolasPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.pergolasNotes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Check for others structural conflicts (screen / columns)?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.pergolas2" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.pergolas2 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="pergolas2FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(pergolas2FilesPhotos, pergolas2Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in pergolas2Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(pergolas2Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.pergolas2Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>On the column it should be frame installation not install?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.pergolas3" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.pergolas3 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="pergolas3FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(pergolas3FilesPhotos, pergolas3Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in pergolas3Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(pergolas3Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.pergolas3Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>On the column it should be frame installation not install?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.pergolas4" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.pergolas4 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="pergolas4FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(pergolas4FilesPhotos, pergolas4Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in pergolas4Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(pergolas4Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.pergolas4Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>If pergola footers are by others should be done after patio grade if by us, we can do it at dig or after patio grade?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.pergolas5" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.pergolas5 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="pergolas5FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(pergolas5FilesPhotos, pergolas5Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in pergolas5Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(pergolas5Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.pergolas5Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>At patio grade needs to address with HO about landscaping and fence?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.pergolas6" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.pergolas6 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="pergolas6FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(pergolas6FilesPhotos, pergolas6Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in pergolas6Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(pergolas6Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.pergolas6Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    v-if="isPergola == true"
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionPergolas" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.pergolasType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.pergolasType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.pergolas, serviceDraftingRequest.pergolasNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>
                
                <!-- BBQs -->
                <v-col
                    v-if="isBBQ == true"
                    class="colPaddingTop"
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="titleFont"
                                >
                                    <v-icon color="primary" left>
                                        mdi-grill
                                    </v-icon>
                                    {{ titles.bbq }}
                                </v-col>
                                <v-col
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <v-tooltip 
                                        v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.bbqNeedsApproval == 1"
                                        left
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn  
                                                v-if="userLoggedGetters.profile.systemAdmin == 1 && serviceTechSpecRequest.bbqNeedsApproval == 1"
                                                class="mx-2 btnAlert"
                                                v-on="on"
                                                @click.stop=""
                                                @click="showApproveOrDenyYesNoAndNotes(titles.bbq, serviceTechSpecRequest.bbqNewValue, serviceTechSpecRequest.bbqNotesNewValue, bbqPhotos)"
                                            >
                                                Review pending changes
                                            </v-btn>
                                        </template>
                                        <span>Approve or Deny</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Already composed in the Pool Studio?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbq" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>                            
                            <v-row v-if="serviceTechSpecRequest.bbq == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbqFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbqFilesPhotos, bbqPhotos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbqPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbqPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbqNotes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>At backfill – plumbing inspection should show that the They will need a BBQ underground / gas if applicable / plumbing if applicable?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ2" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ2 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq2FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq2FilesPhotos, bbq2Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq2Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq2Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ2Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Bonding BBQ at patio grade?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ3" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ3 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq3FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq3FilesPhotos, bbq3Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq3Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq3Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ3Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Check BBQ measurements at pre site?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ4" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ4 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq4FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq4FilesPhotos, bbq4Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq4Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq4Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ4Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>If HO will supply BBQ appliance need to confirm the measurements?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ5" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ5 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq5FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq5FilesPhotos, bbq5Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq5Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq5Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ5Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>At backfill address pergola and BBQ?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ6" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ6 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq6FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq6FilesPhotos, bbq6Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq6Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq6Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ6Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>BBQ tie down should be together with framing not with drywall?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ7" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ7 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq7FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq7FilesPhotos, bbq7Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq7Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq7Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ7Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Natural or Propane should show in the engineer sheet?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ8" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ8 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq8FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq8FilesPhotos, bbq8Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq8Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq8Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ8Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Final electrical / final gas / final plumbing should all happen at the same time?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ9" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ9 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq9FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq9FilesPhotos, bbq9Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq9Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq9Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ9Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Make sure appliance and appliance measurements are confirmed before framing or else job sits?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ10" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ10 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq10FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq10FilesPhotos, bbq10Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq10Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq10Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ10Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider> </v-divider>

                            <v-row
                                class="rowPaddingTop"
                            >
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Flag revisions so we won’t miss it?</label>
                                    <v-radio-group 
                                        v-model="serviceTechSpecRequest.bbQ11" 
                                        row
                                        :rules=[validations.required]
                                    >
                                        <v-radio
                                            label="Yes"
                                            :value="1"
                                            color="var(--color__main)"
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="2"
                                            color="var(--color__main)"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="serviceTechSpecRequest.bbQ11 == 2">
                                <v-col
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Photos (All Images Types)</label>
                                            <v-file-input
                                                v-model="bbq11FilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(bbq11FilesPhotos, bbq11Photos)"
                                                dense
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbq11Photos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card
                                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                            >
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="150"
                                                    max-width="150"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(bbq11Photos, itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbQ11Notes"
                                                rows="6"
                                                row-height="35"
                                                auto-grow
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-col>
                <v-col
                    v-if="isBBQ == true"
                    style="display: flex; align-content: center; align-items: center;"
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                >
                    <v-icon v-if="checkSessionBBQ" color="primary">
                        mdi-check
                    </v-icon>
                    <v-icon v-else color="warning">
                        mdi-alert
                    </v-icon>

                    <v-tooltip 
                        v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.bbqType == 0 && serviceProjectResponse.status <= 7"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon 
                                v-if="serviceDraftingRequest.id != 0 && serviceDraftingRequest.bbqType == 0 && serviceProjectResponse.status <= 7"
                                right
                                color="primary"
                                v-on="on"
                                @click="showDraftingReason(titles.bbq, serviceDraftingRequest.bbqNotes)"
                            >
                                mdi-message-bulleted
                            </v-icon>
                        </template>
                        <span>View the Reason</span>
                    </v-tooltip>
                </v-col>

            </v-expansion-panels>
        </v-row>

        <v-row
            style="padding-top: 50px"
        >
            <v-col 
                cols="12"
            >
                <v-card-actions>
                    <div style="width: 100%; text-align: center;">

                        <v-tooltip 
                            top 
                            v-if="showBackButton"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-if="showBackButton"
                                    class="mx-2"
                                    v-on="on"
                                    style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                    @click="back()"
                                >
                                    Back
                                </v-btn>
                            </template>
                            <span>Back</span>
                        </v-tooltip>

                        <v-tooltip
                            top
                            v-if="showCancelButton"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn 
                                    v-if="showCancelButton"
                                    class="mx-2"
                                    v-on="on"
                                    @click="cancel"
                                    style="background-color: var(--color__red) !important; color: #ffffff !important;"
                                >
                                    Cancel
                                </v-btn>
                            </template>
                            <span>Cancel</span>
                        </v-tooltip>

                        <v-tooltip
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="mx-2"
                                    v-on="on"
                                    :style="showFinishButton ? 'background-color: var(--color__silver) !important; color: #ffffff !important' : ''"
                                    :disabled="projectSelected == null || projectSelected == undefined"
                                    @click="saveTechSpec()"
                                >
                                    Save
                                </v-btn>
                            </template>
                            <span>Save Technical Specification</span>
                        </v-tooltip>
                        
                        <v-tooltip
                            top
                            v-if="showFinishButton"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-if="showFinishButton"
                                    class="mx-2"
                                    v-on="on"
                                    @click="confirmFinishTechSpec"
                                >
                                    Finish
                                </v-btn>
                            </template>
                            <span>Finish and send to Drafting</span>
                        </v-tooltip>
                    </div>
                </v-card-actions>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showDialogDraftingItemNote"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    {{ itemTitle }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Reason</label>                                        
                            <v-textarea
                                v-model="itemReasonDescription"
                                single-line
                                rows="3"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 dialogButtonDismiss"
                            outlined
                            @click="showDialogDraftingItemNote = false"
                        >
                            Dismiss
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="showDialogFenceType"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Approve or Deny - {{ titles.fenceEnclosureInformation }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>{{ titles.fenceEnclosureInformation }} Informed</label>
                            <v-combobox
                                v-model="fenceTypeNewValueSelected"
                                :items="listFenceType"
                                disabled
                                item-text="description"
                                item-value="id"
                                chips
                                multiple
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 dialogButtonDismiss"
                            outlined
                            @click="showDialogFenceType = false"
                        >
                            Dismiss
                        </v-btn>
                    </div>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2"
                            outlined
                            @click="approveFenceType()"
                        >
                            Approve
                        </v-btn>
                    </div>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2"
                            style="background-color: var(--color__red) !important; color: #ffffff !important;"
                            outlined
                            @click="denyFenceType()"
                        >
                            Deny
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="showDialogYesNoAndNotes"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Approve or Deny - {{ yesNoAndNotesTitle }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Already composed in the Pool Studio?</label>
                            <v-radio-group 
                                v-model="yesNo" 
                                row
                                disabled                                
                            >
                                <v-radio
                                    label="Yes"
                                    :value="1"
                                    color="var(--color__main)"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    :value="2"
                                    color="var(--color__main)"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="dialogPhotos.length > 0" style="padding-bottom: 20px;">
                        <v-col
                            v-for="(itemPhoto, index) in dialogPhotos.filter(pht => pht.deleted == false)"
                            :key="index"
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <v-card
                                @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                            >
                                <v-img
                                    :src="itemPhoto.src"
                                    :lazy-src="itemPhoto.src"
                                    aspect-ratio="1"
                                    max-height="150"
                                    max-width="150"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>
                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            <v-textarea
                                v-model="notes"
                                rows="4"
                                row-height="25"
                                auto-grow
                                outlined
                                disabled
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 dialogButtonDismiss"
                            outlined
                            @click="showDialogYesNoAndNotes = false"
                        >
                            Dismiss
                        </v-btn>
                    </div>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2"
                            outlined
                            @click="approveYesNoAndNotes()"
                        >
                            Approve
                        </v-btn>
                    </div>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2"
                            style="background-color: var(--color__red) !important; color: #ffffff !important;"
                            outlined
                            @click="denyYesNoAndNotes()"
                        >
                            Deny
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

		<DialogMessage 
			v-on:methodOKToCall="dialogMessage.methodOK"
			:showDialogMessage.sync="dialogMessage.show"
			:headerTitle="dialogMessage.headerText"
			:bodyText="dialogMessage.bodyText"
		/>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required, requiredFile } from "@/utilities/Rules";
    import { formaterDecimalBR } from '@/utilities/Utils';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";
    import DialogMessage from "@/components/Layout/DialogMessage";
    import { URL_ATTACHMENT } from "@/utilities/Enums";

	export default {

        components: {
            ActionDialog,
            DialogMessage
        },

        mixins: [Helpers],

        props: {
			
            project: {
                default: null
            },
			
            listProjects: {
                default: null
            },

			showBackButton: {
				default: true
			},

			showCancelButton: {
				default: true
			},

			showFinishButton: {
				default: true
			},

			showNotificationApprovedOrDenied: {
				default: false
			},

            forceUpdate: {
                default: false
            },
		},
		
        data: () => ({
            
			URL_ATTACHMENT,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
                status: 0
            },

            panel: [0, 1, 2, 3, 4, 5, 6, 7, 8],

            projectSelected: null,

            surveyFileId: 0,
            surveyFile: [],

            poolStudioDXFFileId: 0,
            poolStudioDXFFile: [],

            poolStudioPDFFileId: 0,
            poolStudioPDFFile: [],

            poolStudioFilesPhotos: [],
            poolStudioPhotos: [],

            architecturalSitePlanFileId: 0,
            architecturalSitePlanFile: [],

            dimensionedEquipmentLocationFilesPhotos: [],
            dimensionedEquipmentLocationPhotos: [],

            fenceLocationFilesPhotos: [],
            fenceLocationPhotos: [],

            depthDeepestPointLocationFilesPhotos: [],
            depthDeepestPointLocationPhotos: [],

            lightLocationsFilesPhotos: [],
            lightLocationsPhotos: [],

            soilTestFileId: 0,
            soilTestFile: [],

            pergolasFilesPhotos: [],
            pergolasPhotos: [],
            pergolas2FilesPhotos: [],
            pergolas2Photos: [],
            pergolas3FilesPhotos: [],
            pergolas3Photos: [],
            pergolas4FilesPhotos: [],
            pergolas4Photos: [],
            pergolas5FilesPhotos: [],
            pergolas5Photos: [],
            pergolas6FilesPhotos: [],
            pergolas6Photos: [],

            waterFeaturesFilesPhotos: [],
            waterFeaturesPhotos: [],

            bbqFilesPhotos: [],
            bbqPhotos: [],
            bbq2FilesPhotos: [],
            bbq2Photos: [],
            bbq3FilesPhotos: [],
            bbq3Photos: [],
            bbq4FilesPhotos: [],
            bbq4Photos: [],
            bbq5FilesPhotos: [],
            bbq5Photos: [],
            bbq6FilesPhotos: [],
            bbq6Photos: [],
            bbq7FilesPhotos: [],
            bbq7Photos: [],
            bbq8FilesPhotos: [],
            bbq8Photos: [],
            bbq9FilesPhotos: [],
            bbq9Photos: [],
            bbq10FilesPhotos: [],
            bbq10Photos: [],
            bbq11FilesPhotos: [],
            bbq11Photos: [],

            listPhotosDelete: [],
            itemPhotoDelete: {},

            showDialogFenceType: false,
            fenceTypeSelected: [],
            fenceTypeNewValueSelected: [],

            showDialogYesNoAndNotes: false,
            yesNoAndNotesTitle: "",
            yesNo: 0,
            dialogPhotos: [],
            notes: "",

            serviceTechSpecRequest: {
                id: 0,
                idService: 0,
                surveyTypeSelected: null,

                dimensionedEquipmentLocation: 0,
                dimensionedEquipmentLocationNotes: "",

                fenceType: "",
                fenceTypeNeedsApproval: 0,
                fenceTypeNewValue: "",
                
                fenceLocation: 0,
                fenceLocationNotes: "",
                fenceLocationNeedsApproval: 0,
                fenceLocationNewValue: 0,
                fenceLocationNotesNewValue: "",

                depthDeepestPointLocation: 0,
                depthDeepestPointLocationNotes: "",

                lightLocations: 0,
                lightLocationsNotes: "",

                soilTest: 0,

                pergolas: 0,
                pergolasNotes: "",
                pergolas2: 0,
                pergolas2Notes: "",
                pergolas3: 0,
                pergolas3Notes: "",
                pergolas4: 0,
                pergolas4Notes: "",
                pergolas5: 0,
                pergolas5Notes: "",
                pergolas6: 0,
                pergolas6Notes: "",

                waterFeatures: 0,
                waterFeaturesNotes: "",

                bbq: 0,
                bbqNotes: "",
                bbQ2: 0,
                bbQ2Notes: "",
                bbQ3: 0,
                bbQ3Notes: "",
                bbQ4: 0,
                bbQ4Notes: "",
                bbQ5: 0,
                bbQ5Notes: "",
                bbQ6: 0,
                bbQ6Notes: "",
                bbQ7: 0,
                bbQ7Notes: "",
                bbQ8: 0,
                bbQ8Notes: "",
                bbQ9: 0,
                bbQ9Notes: "",
                bbQ10: 0,
                bbQ10Notes: "",
                bbQ11: 0,
                bbQ11Notes: "",
            },

            engineeringNotes: '',

            listSurveyType: [
                { id: 1, description: 'Temp' },
                { id: 2, description: 'Final' },
                { id: 3, description: 'More Than 5 Year' }
            ],

            listFenceType: [
                { id: 1, description: 'Pool Cover' },
                { id: 2, description: 'Baby Fence' },
                { id: 3, description: 'Alarm' },
                { id: 4, description: 'Combination' },
                { id: 5, description: 'Screen Enclosure' },
                { id: 6, description: 'AI Cameras' },
                { id: 7, description: 'Perimeter Fence' },
                { id: 8, description: 'Floating Alarm' },
                { id: 9, description: 'Cooping Alarm' },
            ],

            itemTitle: "",
            itemReasonDescription: "",
            showDialogDraftingItemNote: false,
            
            serviceDraftingUploadFilesRequest: {
                id: 0,
                idService: 0,
                poolPlanNotes: "",
                designFilesNotes: "",
                imagesNotes: "",
                engineeringNotes: ""
            },

            titles: {
                details: "Attachments",
                dimensionedEquipmentLocation: "Equipment Location",
                fenceEnclosureInformation: "Pool Barrier",
                fenceLocation: "Fence Location",
                depthDeepestPointLocation: "Depth Deepest Point Location",
                lightLocations: "Light Locations",
                soilTest: "Soil Test",
                pergolas: "Pergolas",
                waterFeatures: "Water Features",
                bbq: "BBQ´s",
            },

            serviceDraftingRequest: {
                id: 0,
                idService: 0,
                serviceInformationType: null,
                serviceInformationNotes: "",
                scopeOfWorkType: null,
                scopeOfWorkNotes: "",
                detailsType: null,
                detailsNotes: "",
                dimensionedEquipmentLocationType: null,
                dimensionedEquipmentLocationNotes: "",
                fenceEnclosureInformationType: null,
                fenceEnclosureInformationNotes: "",
                fenceLocationType: null,
                fenceLocationNotes: "",
                depthDeepestPointLocationType: null,
                depthDeepestPointLocationNotes: "",
                lightLocationsType: null,
                lightLocationsNotes: "",
                soilTestType: null,
                soilTestNotes: "",
                pergolasType: null,
                pergolasNotes: "",
                waterFeaturesType: null,
                waterFeaturesNotes: "",
                bbqType: null,
                bbqNotes: ""
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogMessage: {
                show: false,
                headerText: "",
                bodyText: "",
                methodOK: ( () => {} ),
            },

            validations: {
                file: requiredFile,
                required: required
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            checkSessionDetails() {
                let validation = true;

                // if (this.serviceTechSpecRequest.surveyTypeSelected == null || this.serviceTechSpecRequest.surveyTypeSelected == undefined) {
                //     validation = false;
                // }
                
                // validation = validation ? this.checkEmptyFile(this.surveyFile) : validation;
                // validation = validation ? this.checkEmptyFile(this.poolStudioDXFFile) : validation;
                // validation = validation ? this.checkEmptyFile(this.poolStudioPDFFile) : validation;

                return validation
            },
            
            checkSessionDimensionedEquipmentLocation() {
                let validation = true;

                if (this.serviceTechSpecRequest.dimensionedEquipmentLocation == null || this.serviceTechSpecRequest.dimensionedEquipmentLocation == undefined || this.serviceTechSpecRequest.dimensionedEquipmentLocation == 0 || (this.userLoggedGetters.profile.systemAdmin == 1 && this.serviceTechSpecRequest.dimensionedEquipmentLocationNeedsApproval == 1)) {
                    validation = false;
                }
                else if (this.serviceTechSpecRequest.dimensionedEquipmentLocation == 2) {

                    validation = validation ? this.checkEmptyFile(this.dimensionedEquipmentLocationPhotos) : validation;
                }

                return validation
            },
            
            checkSessionFenceEnclosureInformation() {
                let validation = true;

                if (this.fenceTypeSelected == null || this.fenceTypeSelected == undefined || this.fenceTypeSelected == "" || this.fenceTypeSelected.length <= 0 || (this.userLoggedGetters.profile.systemAdmin == 1 && this.serviceTechSpecRequest.fenceTypeNeedsApproval == 1)) {
                    validation = false;
                }

                return validation
            },
            
            checkSessionFenceLocation() { 
                let validation = true;

                if (this.serviceTechSpecRequest.fenceLocation == null || this.serviceTechSpecRequest.fenceLocation == undefined || this.serviceTechSpecRequest.fenceLocation == 0 || (this.userLoggedGetters.profile.systemAdmin == 1 && this.serviceTechSpecRequest.fenceLocationNeedsApproval == 1)) {
                    validation = false;
                }
                else if (this.serviceTechSpecRequest.fenceLocation == 2) {

                    validation = validation ? this.checkEmptyFile(this.fenceLocationPhotos) : validation;
                }

                return validation
            },
            
            checkSessionDepthDeepestPointLocation() {
                let validation = true;

                if (this.serviceTechSpecRequest.depthDeepestPointLocation == null || this.serviceTechSpecRequest.depthDeepestPointLocation == undefined || this.serviceTechSpecRequest.depthDeepestPointLocation == 0 || (this.userLoggedGetters.profile.systemAdmin == 1 && this.serviceTechSpecRequest.depthDeepestPointLocationNeedsApproval == 1)) {
                    validation = false;
                }
                else if (this.serviceTechSpecRequest.depthDeepestPointLocation == 2) {

                    validation = validation ? this.checkEmptyFile(this.depthDeepestPointLocationPhotos) : validation;
                }

                return validation
            },

            checkSessionLightLocation() {
                let validation = true;

                if (this.serviceTechSpecRequest.lightLocations == null || this.serviceTechSpecRequest.lightLocations == undefined || this.serviceTechSpecRequest.lightLocations == 0 || (this.userLoggedGetters.profile.systemAdmin == 1 && this.serviceTechSpecRequest.lightLocationsNeedsApproval == 1)) {
                    validation = false;
                }
                else if (this.serviceTechSpecRequest.lightLocations == 2) {

                    validation = validation ? this.checkEmptyFile(this.lightLocationsPhotos) : validation;
                }

                return validation
            },
            
            checkSessionSoilTest() {
                let validation = true;

                if (this.serviceTechSpecRequest.soilTest == null || this.serviceTechSpecRequest.soilTest == undefined || this.serviceTechSpecRequest.soilTest == 0) {
                    validation = false;
                }
                else if (this.serviceTechSpecRequest.soilTest == 1) {
                    validation = validation ? this.checkEmptyFile(this.soilTestFile) : validation;
                }

                return validation
            },
            
            checkSessionPergolas() {
                let validation = true;

                if (this.serviceTechSpecRequest.pergolas == null || this.serviceTechSpecRequest.pergolas == undefined || this.serviceTechSpecRequest.pergolas == 0 || (this.userLoggedGetters.profile.systemAdmin == 1 && this.serviceTechSpecRequest.pergolasNeedsApproval == 1)) {
                    validation = false;
                }
                else if (this.serviceTechSpecRequest.pergolas == 2) {

                    validation = validation ? this.checkEmptyFile(this.pergolasPhotos) : validation;
                }

                return validation
            },
            
            checkSessionWaterFeatures() {
                let validation = true;

                if (this.serviceTechSpecRequest.waterFeatures == null || this.serviceTechSpecRequest.waterFeatures == undefined || this.serviceTechSpecRequest.waterFeatures == 0 || (this.userLoggedGetters.profile.systemAdmin == 1 && this.serviceTechSpecRequest.waterFeaturesNeedsApproval == 1)) {
                    validation = false;
                }
                else if (this.serviceTechSpecRequest.waterFeatures == 2) {

                    validation = validation ? this.checkEmptyFile(this.waterFeaturesPhotos) : validation;
                }

                return validation
            },
            
            checkSessionBBQ() {
                let validation = true;

                if (this.serviceTechSpecRequest.bbq == null || this.serviceTechSpecRequest.bbq == undefined || this.serviceTechSpecRequest.bbq == 0 || (this.userLoggedGetters.profile.systemAdmin == 1 && this.serviceTechSpecRequest.bbqNeedsApproval == 1)) {
                    validation = false;
                }
                else if (this.serviceTechSpecRequest.bbq == 2) {

                    validation = validation ? this.checkEmptyFile(this.bbqPhotos) : validation;
                }

                return validation
            },

            checkAllSessionIsValid() {
                return (this.serviceDraftingRequest.id != 0)
                    ||(this.checkSessionDetails 
                    && this.checkSessionDimensionedEquipmentLocation
                    && this.checkSessionFenceEnclosureInformation
                    && this.checkSessionFenceLocation
                    && this.checkSessionDepthDeepestPointLocation
                    && this.checkSessionLightLocation
                    && this.checkSessionSoilTest
                    && this.checkSessionPergolas
                    && this.checkSessionWaterFeatures
                    && this.checkSessionBBQ);
            },

            calculateGrandTotal() {
                try {
                    let grandTotal = this.grandTotalProductsUnFormatted;

                    return `$ ${formaterDecimalBR(grandTotal)}`;
                }
                catch {
                    return `$ 0.00`
                }
            },

            showEngineeringNotes() {
                return this.serviceDraftingUploadFilesRequest.engineeringNotes != null && 
                       this.serviceDraftingUploadFilesRequest.engineeringNotes != undefined && 
                       this.serviceDraftingUploadFilesRequest.engineeringNotes.toString().trim() != '';
            },

            isBBQ() {
                return this.projectSelected != null && 
                       this.projectSelected != undefined && 
                       this.projectSelected.bbq == 1;
            },

            isPergola() {
                return this.projectSelected != null && 
                       this.projectSelected != undefined && 
                       this.projectSelected.pergola == 1;
            },

            isPool() {
                return this.projectSelected != null && 
                       this.projectSelected != undefined &&
                       this.projectSelected.pool == 1;
            }
        },

        watch: {

            async "forceUpdate"() {

                if (this.project != null && this.project != undefined) {
                    this.projectSelected = this.project;
                    await this.updateTechSpec();
                }
            },

            async "project"() {

                if (this.project != null && this.project != undefined) {
                    this.projectSelected = this.project;
                    await this.updateTechSpec();
                }
            }
        },

        methods: {

            async back() {
                history.go(-1);
            },

            async cancel() {
                this.$emit("cancel");
            },

            async getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },

            async updateTechSpec() {

                let idServiceProject = await this.getIDProject();

                let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", idServiceProject);

                this.serviceProjectResponse = response.result;

                let responseDrafting = await this.$store.dispatch("serviceModule/GetDraftingById", idServiceProject);
                this.serviceDraftingRequest = responseDrafting.result;
                
                let responseDraftingUploadFiles = await this.$store.dispatch("serviceModule/GetDraftingUploadFilesById", idServiceProject);

                if (responseDraftingUploadFiles.result.id != 0) {
                    this.serviceDraftingUploadFilesRequest = responseDraftingUploadFiles.result;
                    this.engineeringNotes = this.serviceDraftingUploadFilesRequest.engineeringNotes;
                }

                await this.getTechSpecByProjectID(idServiceProject);
            },

            async clearFields() {

                this.surveyFileId = 0;
                this.surveyFile = [];
                this.poolStudioDXFFileId = 0;
                this.poolStudioDXFFile = [];
                this.poolStudioPDFFileId = 0;
                this.poolStudioPDFFile = [];
                this.poolStudioFilesPhotos = undefined;
                this.poolStudioPhotos = [];
                this.architecturalSitePlanFileId = 0;
                this.architecturalSitePlanFile = [];
                this.dimensionedEquipmentLocationFilesPhotos = [];
                this.dimensionedEquipmentLocationPhotos = [];
                this.fenceTypeSelected = [];
                this.fenceLocationFilesPhotos = undefined;
                this.fenceLocationPhotos = [];
                this.depthDeepestPointLocationFilesPhotos = undefined;
                this.depthDeepestPointLocationPhotos = [];
                this.lightLocationsFilesPhotos = undefined;
                this.lightLocationsPhotos = [];
                this.soilTestFileId = 0;
                this.soilTestFile = [];
                this.pergolasFilesPhotos = undefined;
                this.pergolasPhotos = [];
                this.pergolas2FilesPhotos = undefined;
                this.pergolas2Photos = [];
                this.pergolas3FilesPhotos = undefined;
                this.pergolas3Photos = [];
                this.pergolas4FilesPhotos = undefined;
                this.pergolas4Photos = [];
                this.pergolas5FilesPhotos = undefined;
                this.pergolas5Photos = [];
                this.pergolas6FilesPhotos = undefined;
                this.pergolas6Photos = [];
                this.waterFeaturesFilesPhotos = undefined;
                this.waterFeaturesPhotos = [];
                this.bbqFilesPhotos = undefined;
                this.bbqPhotos = [];
                this.bbq2FilesPhotos = undefined;
                this.bbq2Photos = [];
                this.bbq3FilesPhotos = undefined;
                this.bbq3Photos = [];
                this.bbq4FilesPhotos = undefined;
                this.bbq4Photos = [];
                this.bbq5FilesPhotos = undefined;
                this.bbq5Photos = [];
                this.bbq6FilesPhotos = undefined;
                this.bbq6Photos = [];
                this.bbq7FilesPhotos = undefined;
                this.bbq7Photos = [];
                this.bbq8FilesPhotos = undefined;
                this.bbq8Photos = [];
                this.bbq9FilesPhotos = undefined;
                this.bbq9Photos = [];
                this.bbq10FilesPhotos = undefined;
                this.bbq10Photos = [];
                this.bbq11FilesPhotos = undefined;
                this.bbq11Photos = [];

            },

            async getTechSpecByProjectID(idServiceProject) {

                const responseTechSpec = await this.$store.dispatch("serviceModule/GetTechSpecById", idServiceProject);
                this.serviceTechSpecRequest = responseTechSpec.result;

                await this.clearFields();

                if (this.serviceTechSpecRequest.id != 0) {
                    this.serviceTechSpecRequest.surveyTypeSelected = {
                        id: this.serviceTechSpecRequest.surveyType,
                        description: this.serviceTechSpecRequest.surveyTypeDescription
                    }
                    
                    const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PROJECT.value, idKey: idServiceProject });

                    //ATTACHMENTS
                    let itemSessionDetailSurveyType = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SURVEY.value)[0];
                    if (itemSessionDetailSurveyType != null && itemSessionDetailSurveyType != undefined) {
                        this.surveyFileId = itemSessionDetailSurveyType.id;
                        this.surveyFile = new File([], itemSessionDetailSurveyType.fileName);
                    }

                    let itemSessionDetailPoolStudioDXF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_DXF.value)[0];
                    if (itemSessionDetailPoolStudioDXF != null && itemSessionDetailPoolStudioDXF != undefined) {
                        this.poolStudioDXFFileId = itemSessionDetailPoolStudioDXF.id;
                        this.poolStudioDXFFile = new File([], itemSessionDetailPoolStudioDXF.fileName);
                    }

                    let itemSessionDetailPoolStudioPDF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_PDF.value)[0];
                    if (itemSessionDetailPoolStudioPDF != null && itemSessionDetailPoolStudioPDF != undefined) {
                        this.poolStudioPDFFileId = itemSessionDetailPoolStudioPDF.id;
                        this.poolStudioPDFFile = new File([], itemSessionDetailPoolStudioPDF.fileName);
                    }
                    
                    let listItemSessionPoolStudio = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_IMAGES.value);  


                    listItemSessionPoolStudio.forEach(itemPhoto => {

                        this.poolStudioPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })
                    })

                    let itemSessionDetailArchitecturalSitePlan = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.ARCHITECTURAL_SITE_PLAN.value)[0];
                    if (itemSessionDetailArchitecturalSitePlan != null && itemSessionDetailArchitecturalSitePlan != undefined) {
                        this.architecturalSitePlanFileId = itemSessionDetailArchitecturalSitePlan.id;
                        this.architecturalSitePlanFile = new File([], itemSessionDetailArchitecturalSitePlan.fileName);
                    }
                    
                    //EQUIPMENT LOCATION
                    let listItemSessionDimensionedEquipmentLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DIMENSIONED_EQUIPMENT_LOCATION.value);  
                    listItemSessionDimensionedEquipmentLocation.forEach(itemPhoto => {

                        this.dimensionedEquipmentLocationPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    // POOL BARRIER
                    this.fenceTypeSelected = [];
                    if (this.serviceTechSpecRequest.fenceType != null && this.serviceTechSpecRequest.fenceType != undefined && this.serviceTechSpecRequest.fenceType.length > 0) {
                        let listIDFenceType = this.serviceTechSpecRequest.fenceType.split(';');
                        await listIDFenceType.forEach(itemFence => {
                            if (itemFence) {

                                this.fenceTypeSelected.push({
                                    id: parseInt(itemFence),
                                    description: this.listFenceType.filter(fen => fen.id == parseInt(itemFence))[0].description
                                })
                            }
                        })
                    }
                    
                    //FENCE LOCATION
                    let listItemSessionFenceLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.FENCE_LOCATION.value);
                    listItemSessionFenceLocation.forEach(itemPhoto => {

                        this.fenceLocationPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })
                    
                    //DEPH DEEPEST POINT LOCATION
                    let listItemSessionDepthDeepestPointLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DEPH_DEEPEST_POINT_LOCATION.value);  
                    listItemSessionDepthDeepestPointLocation.forEach(itemPhoto => {

                        this.depthDeepestPointLocationPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })
                    
                    //LIGHT LOCATIONS
                    let listItemSessionLightLocations = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.LIGHT_LOCATIONS.value);
                    listItemSessionLightLocations.forEach(itemPhoto => {

                        this.lightLocationsPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    //SOIL TEST
                    let itemSessionSoilTest = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SOIL_TEST.value)[0];
                    if (itemSessionSoilTest != null && itemSessionSoilTest != undefined) {
                        this.soilTestFileId = itemSessionSoilTest.id;
                        this.soilTestFile = new File([], itemSessionSoilTest.fileName);
                    }
                    
                    //PERGOLAS
                    let listItemSessionPergolas = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS.value);
                    listItemSessionPergolas.forEach(itemPhoto => {

                        this.pergolasPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })
                    
                    let listItemSessionPergolas2 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS2.value);
                    listItemSessionPergolas2.forEach(itemPhoto => {

                        this.pergolas2Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionPergolas3 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS3.value);
                    listItemSessionPergolas3.forEach(itemPhoto => {

                        this.pergolas3Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionPergolas4 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS4.value);
                    listItemSessionPergolas4.forEach(itemPhoto => {

                        this.pergolas4Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionPergolas5 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS5.value);
                    listItemSessionPergolas5.forEach(itemPhoto => {

                        this.pergolas5Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionPergolas6 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS6.value);
                    listItemSessionPergolas6.forEach(itemPhoto => {

                        this.pergolas6Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })
                    
                    //WATER FEATURES
                    let listItemSessionWaterFeatures = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.WATER_FEATURES.value);  
                    listItemSessionWaterFeatures.forEach(itemPhoto => {

                        this.waterFeaturesPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })
                    
                    //BBQ
                    let listItemSessionBBQ = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ.value);  
                    listItemSessionBBQ.forEach(itemPhoto => {

                        this.bbqPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })
                    
                    let listItemSessionBBQ2 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ2.value);  
                    listItemSessionBBQ2.forEach(itemPhoto => {

                        this.bbq2Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ3 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ3.value);  
                    listItemSessionBBQ3.forEach(itemPhoto => {

                        this.bbq3Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ4 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ4.value);  
                    listItemSessionBBQ4.forEach(itemPhoto => {

                        this.bbq4Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ5 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ5.value);  
                    listItemSessionBBQ5.forEach(itemPhoto => {

                        this.bbq5Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ6 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ6.value);  
                    listItemSessionBBQ6.forEach(itemPhoto => {

                        this.bbq6Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ7 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ7.value);  
                    listItemSessionBBQ7.forEach(itemPhoto => {

                        this.bbq7Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ8 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ8.value);  
                    listItemSessionBBQ8.forEach(itemPhoto => {

                        this.bbq8Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ9 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ9.value);  
                    listItemSessionBBQ9.forEach(itemPhoto => {

                        this.bbq9Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ10 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ10.value);  
                    listItemSessionBBQ10.forEach(itemPhoto => {

                        this.bbq10Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    let listItemSessionBBQ11 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ11.value);  
                    listItemSessionBBQ11.forEach(itemPhoto => {

                        this.bbq11Photos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })
                }
            },

            async showDraftingReason(itemTitle, itemDescription) {
                this.itemTitle = itemTitle;
                this.itemReasonDescription = itemDescription;
                this.showDialogDraftingItemNote = true;
            },

            checkEmptyFile(file) {

                if (file == null || file == undefined || file.length <= 0) {
                    return false;
                }
                else {
                    return true
                }
            },

            async confirmPhotoDelete(listPhotos, itemPhotoToDelete) {

                this.listPhotosDelete = listPhotos;
                this.itemPhotoToDelete = itemPhotoToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this image [${itemPhotoToDelete.fileName}], confirm your decision?`,
                    methodConfirm: this.deletePhoto,
                    params: 0
                };
            },

            async deletePhoto() {

                this.itemPhotoToDelete.deleted = true;
            },

            async uploadTechSpecFile(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },
            
            async saveTechSpecAllFiles() {

                let allResult = []

                let idServiceProject = await this.getIDProject();

                //DETAILS - SURVEY
                let result = await this.uploadTechSpecFile(this.surveyFileId, idServiceProject, this.surveyFile, TYPE_DOCUMENT.SURVEY.value);

                if (result.success == false) {
                    allResult.push({
                        typeDocument: TYPE_DOCUMENT.SURVEY.description,
                        message: result.message
                    })
                }
                
                //DETAILS - ATCHITECTURAL SITE PLAN
                result = await this.uploadTechSpecFile(this.architecturalSitePlanFileId, idServiceProject, this.architecturalSitePlanFile, TYPE_DOCUMENT.ARCHITECTURAL_SITE_PLAN.value);

                if (result.success == false) {
                    allResult.push({
                        typeDocument: TYPE_DOCUMENT.ARCHITECTURAL_SITE_PLAN.description,
                        message: result.message
                    })
                }
                
                //DETAILS - POOL STUDIO DXF
                result = await this.uploadTechSpecFile(this.poolStudioDXFFileId, idServiceProject, this.poolStudioDXFFile, TYPE_DOCUMENT.POOL_STUDIO_DXF.value);

                if (result.success == false) {
                    allResult.push({
                        typeDocument: TYPE_DOCUMENT.POOL_STUDIO_DXF.description,
                        message: result.message
                    })
                }

                //DETAILS - POOL STUDIO PDF
                result = await this.uploadTechSpecFile(this.poolStudioPDFFileId, idServiceProject, this.poolStudioPDFFile, TYPE_DOCUMENT.POOL_STUDIO_PDF.value);

                if (result.success == false) {
                    allResult.push({
                        typeDocument: TYPE_DOCUMENT.POOL_STUDIO_PDF.description,
                        message: result.message
                    })
                }

                //POOL STUDIO IMAGES
                this.poolStudioPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.POOL_STUDIO_IMAGES.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.POOL_STUDIO_IMAGES.description,
                            message: result.message
                        })
                    }
                })

                //EQUIPMENT LOCATION
                this.dimensionedEquipmentLocationPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.DIMENSIONED_EQUIPMENT_LOCATION.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DIMENSIONED_EQUIPMENT_LOCATION.description,
                            message: result.message
                        })
                    }
                })

                //FENCE LOCATION
                this.fenceLocationPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.FENCE_LOCATION.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.FENCE_LOCATION.description,
                            message: result.message
                        })
                    }
                })

                //DEPH DEEPEST POINT LOCATION
                this.depthDeepestPointLocationPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.DEPH_DEEPEST_POINT_LOCATION.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DEPH_DEEPEST_POINT_LOCATION.description,
                            message: result.message
                        })
                    }
                })

                //LIGHT LOCATIONS
                this.lightLocationsPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.LIGHT_LOCATIONS.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.LIGHT_LOCATIONS.description,
                            message: result.message
                        })
                    }
                })

                //SOIL TEST      
                result = await this.uploadTechSpecFile(this.soilTestFileId, idServiceProject, this.soilTestFile, TYPE_DOCUMENT.SOIL_TEST.value);

                if (result.success == false) {
                    allResult.push({
                        typeDocument: TYPE_DOCUMENT.SOIL_TEST.description,
                        message: result.message
                    })
                }

                //PERGOLAS
                this.pergolasPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.PERGOLAS.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERGOLAS.description,
                            message: result.message
                        })
                    }
                })

                this.pergolas2Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.PERGOLAS2.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERGOLAS2.description,
                            message: result.message
                        })
                    }
                })

                this.pergolas3Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.PERGOLAS3.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERGOLAS3.description,
                            message: result.message
                        })
                    }
                })

                this.pergolas4Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.PERGOLAS4.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERGOLAS4.description,
                            message: result.message
                        })
                    }
                })

                this.pergolas5Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.PERGOLAS5.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERGOLAS5.description,
                            message: result.message
                        })
                    }
                })

                this.pergolas6Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.PERGOLAS6.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERGOLAS6.description,
                            message: result.message
                        })
                    }
                })

                //WATER FEATURES
                this.waterFeaturesPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.WATER_FEATURES.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.WATER_FEATURES.description,
                            message: result.message
                        })
                    }
                })

                //BBQs
                this.bbqPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ.description,
                            message: result.message
                        })
                    }
                })
                
                this.bbq2Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ2.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ2.description,
                            message: result.message
                        })
                    }
                })

                this.bbq3Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ3.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ3.description,
                            message: result.message
                        })
                    }
                })

                this.bbq4Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ4.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ4.description,
                            message: result.message
                        })
                    }
                })

                this.bbq5Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ5.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ5.description,
                            message: result.message
                        })
                    }
                })

                this.bbq6Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ6.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ6.description,
                            message: result.message
                        })
                    }
                })

                this.bbq7Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ7.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ7.description,
                            message: result.message
                        })
                    }
                })

                this.bbq8Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ8.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ8.description,
                            message: result.message
                        })
                    }
                })

                this.bbq9Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ9.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ9.description,
                            message: result.message
                        })
                    }
                })

                this.bbq10Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ10.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ10.description,
                            message: result.message
                        })
                    }
                })

                this.bbq11Photos.forEach(async itemPhoto => {

                    let result = await this.uploadTechSpecFile(itemPhoto.id, idServiceProject, itemPhoto.file, TYPE_DOCUMENT.BBQ11.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.BBQ11.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `The [${itemResult.typeDocument}] reported the error: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async saveTechSpec(silent = false) {

                this.showLoading();

                if (await this.saveTechSpecAllFiles()) {

                    this.serviceTechSpecRequest.idServiceProject = await this.getIDProject();

                    if (this.serviceTechSpecRequest.surveyTypeSelected != null && this.serviceTechSpecRequest.surveyTypeSelected != undefined) {
                        this.serviceTechSpecRequest.surveyType = this.serviceTechSpecRequest.surveyTypeSelected.id;
                    }

                    let fenceType = ""
                    if (this.fenceTypeSelected != null && this.fenceTypeSelected != undefined) {

                        this.fenceTypeSelected.forEach(itemFenceType => {
                            fenceType += `${itemFenceType.id};`
                        });
                    }
                    this.serviceTechSpecRequest.fenceType = fenceType;
                    
                    const result = await this.$store.dispatch("serviceModule/CreateUpdateTechSpec", this.serviceTechSpecRequest);

                    if (result.success === true) {
                        
                        if (silent == false) {

                            this.showToast("success", "Success!", result.message);

                            if (this.userLoggedGetters.profile.systemAdmin != 1 && this.showNotificationApprovedOrDenied) {
                                this.dialogMessage = {
                                    show: true,
                                    headerText: 'Success',
                                    bodyText: "Your changes to the technical specification are under review by the administrator and as soon as possible you will receive a notification whether it has been approved or denied.",
                                    methodOK: (() => { }),
                                };
                            }
                        }
                        
                        setTimeout( () => { this.updateTechSpec(); }, 2000);

                        this.hideLoading();
                        
                        return true;
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                        return false;
                    }
                }
            },

            async validateAllTechSpec() {

                return new Promise((resolve) => {
                    this.showLoading();
                    let projectNames = "";
                    let countProjects = 0;

                    this.listProjects.forEach(async itemProject => {

                        let isValid = true;

                        const responseTechSpec = await this.$store.dispatch("serviceModule/GetTechSpecById", itemProject.id);
                        const serviceTechSpec = responseTechSpec.result;

                        const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PROJECT.value, idKey: itemProject.id });

                        //SESSION DETAILS
                        if (serviceTechSpec.surveyType == null || serviceTechSpec.surveyType == undefined || serviceTechSpec.surveyType == 0) {
                            isValid = false;
                        }

                        let itemSessionDetailSurveyType = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SURVEY.value)[0];
                        if (itemSessionDetailSurveyType == null || itemSessionDetailSurveyType == undefined) {
                            isValid = false;
                        }

                        let itemSessionDetailPoolStudioDXF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_DXF.value)[0];
                        if (itemSessionDetailPoolStudioDXF == null || itemSessionDetailPoolStudioDXF == undefined) {
                            isValid = false;
                        }

                        let itemSessionDetailPoolStudioPDF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_PDF.value)[0];
                        if (itemSessionDetailPoolStudioPDF == null || itemSessionDetailPoolStudioPDF == undefined) {
                            isValid = false;
                        }
                        

                        //DIMENSIONED EQUIPAMENT LOCATION
                        if (serviceTechSpec.dimensionedEquipmentLocation == null || serviceTechSpec.dimensionedEquipmentLocation == undefined) {
                            isValid = false;
                        }
                        else if (serviceTechSpec.dimensionedEquipmentLocation == 2) {
                            let listItemSessionDimensionedEquipmentLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DIMENSIONED_EQUIPMENT_LOCATION.value);
                            if (listItemSessionDimensionedEquipmentLocation.length <= 0) {
                                isValid = false;
                            }
                        }

                        //POOL BARRIER
                        if (itemProject.pool == 1) {
                            if (serviceTechSpec.fenceType == null || serviceTechSpec.fenceType == undefined || serviceTechSpec.fenceType == "" || serviceTechSpec.fenceType.length <= 0) {
                                isValid = false;
                            }
                        }

                        //FENCE LOCATION
                        if (serviceTechSpec.fenceLocation == null || serviceTechSpec.fenceLocation == undefined) {
                            isValid = false;
                        }
                        else if (serviceTechSpec.fenceLocation == 2) {
                            let listItemSessionFenceLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.FENCE_LOCATION.value);
                            if (listItemSessionFenceLocation.length <= 0) {
                                isValid = false;
                            }
                        }

                        //DEPH DEEPEST POINT LOCATION
                        if (itemProject.pool == 1) {
                            if (serviceTechSpec.depthDeepestPointLocation == null || serviceTechSpec.depthDeepestPointLocation == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.depthDeepestPointLocation == 2) {
                                let listItemSessionDepthDeepestPointLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DEPH_DEEPEST_POINT_LOCATION.value);  
                                if (listItemSessionDepthDeepestPointLocation.length <= 0) {
                                    isValid = false;
                                }
                            }
                        }

                        //LIGHT LOCATIONS
                        if (serviceTechSpec.lightLocations == null || serviceTechSpec.lightLocations == undefined) {
                            isValid = false;
                        }
                        else if (serviceTechSpec.lightLocations == 2) {
                            let listItemSessionLightLocations = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.LIGHT_LOCATIONS.value);
                            if (listItemSessionLightLocations.length <= 0) {
                                isValid = false;
                            }
                        }

                        //SOIL TEST
                        if (serviceTechSpec.soilTest == null || serviceTechSpec.soilTest == undefined) {
                            isValid = false;
                        }
                        else if (serviceTechSpec.soilTest == 1) {
                            let itemSessionSoilTest = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SOIL_TEST.value)[0];
                            if (itemSessionSoilTest.length <= 0) {
                                isValid = false;
                            }
                        }

                        //PERGOLAS
                        if (itemProject.pergola == 1) {
                            if (serviceTechSpec.pergolas == null || serviceTechSpec.pergolas == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.pergolas == 2) {
                                const listItemSessionPergolas = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS.value);
                                if (listItemSessionPergolas.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.pergolas2 == null || serviceTechSpec.pergolas2 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.pergolas2 == 2) {
                                const listItemSessionPergolas2 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS2.value);
                                if (listItemSessionPergolas2.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.pergolas3 == null || serviceTechSpec.pergolas3 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.pergolas3 == 2) {
                                const listItemSessionPergolas3 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS3.value);
                                if (listItemSessionPergolas3.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.pergolas4 == null || serviceTechSpec.pergolas4 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.pergolas4 == 2) {
                                const listItemSessionPergolas4 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS4.value);
                                if (listItemSessionPergolas4.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.pergolas5 == null || serviceTechSpec.pergolas5 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.pergolas5 == 2) {
                                const listItemSessionPergolas5 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS5.value);
                                if (listItemSessionPergolas5.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.pergolas6 == null || serviceTechSpec.pergolas6 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.pergolas6 == 2) {
                                const listItemSessionPergolas6 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS6.value);
                                if (listItemSessionPergolas6.length <= 0) {
                                    isValid = false;
                                }
                            }
                        }

                        //WATER FEATURES
                        if (serviceTechSpec.waterFeatures == null || serviceTechSpec.waterFeatures == undefined) {
                            isValid = false;
                        }
                        else if (serviceTechSpec.waterFeatures == 2) {
                            const listItemSessionWaterFeatures = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.WATER_FEATURES.value);
                            if (listItemSessionWaterFeatures.length <= 0) {
                                isValid = false;
                            }
                        }

                        //BBQ
                        if (itemProject.bbq == 1) {

                            if (serviceTechSpec.bbq == null || serviceTechSpec.bbq == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbq == 2) {
                                const listItemSessionBBQ = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ.value);
                                if (listItemSessionBBQ.length <= 0) {
                                    isValid = false;
                                }
                            }
                            
                            if (serviceTechSpec.bbQ2 == null || serviceTechSpec.bbQ2 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ2 == 2) {
                                const listItemSessionBBQ2 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ2.value);
                                if (listItemSessionBBQ2.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ3 == null || serviceTechSpec.bbQ3 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ3 == 2) {
                                const listItemSessionBBQ3 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ3.value);
                                if (listItemSessionBBQ3.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ4 == null || serviceTechSpec.bbQ4 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ4 == 2) {
                                const listItemSessionBBQ4 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ4.value);
                                if (listItemSessionBBQ4.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ5 == null || serviceTechSpec.bbQ5 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ5 == 2) {
                                const listItemSessionBBQ5 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ5.value);
                                if (listItemSessionBBQ5.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ6 == null || serviceTechSpec.bbQ6 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ6 == 2) {
                                const listItemSessionBBQ6 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ6.value);
                                if (listItemSessionBBQ6.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ7 == null || serviceTechSpec.bbQ7 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ7 == 2) {
                                const listItemSessionBBQ7 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ7.value);
                                if (listItemSessionBBQ7.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ8 == null || serviceTechSpec.bbQ8 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ8 == 2) {
                                const listItemSessionBBQ8 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ8.value);
                                if (listItemSessionBBQ8.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ9 == null || serviceTechSpec.bbQ9 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ9 == 2) {
                                const listItemSessionBBQ9 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ9.value);
                                if (listItemSessionBBQ9.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ10 == null || serviceTechSpec.bbQ10 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ10 == 2) {
                                const listItemSessionBBQ10 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ10.value);
                                if (listItemSessionBBQ10.length <= 0) {
                                    isValid = false;
                                }
                            }

                            if (serviceTechSpec.bbQ11 == null || serviceTechSpec.bbQ11 == undefined) {
                                isValid = false;
                            }
                            else if (serviceTechSpec.bbQ11 == 2) {
                                const listItemSessionBBQ11 = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ11.value);
                                if (listItemSessionBBQ11.length <= 0) {
                                    isValid = false;
                                }
                            }
                        }

                        if (isValid == false) {
                            projectNames += `${itemProject.name} <br />`
                        }

                        countProjects++;

                        if (countProjects >= this.listProjects.length) {

                            this.hideLoading();

                            if (projectNames != "") {
                                this.showToast("error", "Error!", `You must complete all sessions about projects below: <br /> ${projectNames}`);
                                resolve(false)
                            }
                            else {
                                resolve(true)
                            }
                        }
                    })

                });
            },

            async confirmFinishTechSpec() {

                await this.saveTechSpec(true);

                this.validateAllTechSpec().then(async result => {

                    if (result == true) {
                    
                        let responseLoadCalculation = await this.$store.dispatch("serviceModule/GetLoadCalculationById", { id: this.serviceProjectResponse.idService, idAddress: 0 } );

                        if (responseLoadCalculation.result.id == 0) {
                            this.showToast("warning", "Warning!", "You must complete the load calculation form to finish this step!");
                        }
                        else {

                            this.dialog = {
                                show: true,
                                headerText: 'Confirmation',
                                bodyText: `You will FINISH all SCOPE OF WORK and go to the DRAFTING DEPARTMENT, confirm your decision?`,
                                methodConfirm: this.finishTechSpec,
                                params: 0
                            };
                        }
                    }
                    
                });
            },

            async finishTechSpec() {

                this.showLoading();

                let updateStatusRequest = {
                    id: this.serviceProjectResponse.idService,
                    status: 8 //Drafting - Check List
                };

                let result = await this.$store.dispatch("serviceModule/UpdateStatus", updateStatusRequest);

                if (result.success) {

                    this.listProjects.forEach(itemProject => {

                        let updateStatusRequest = {
                            id: itemProject.id,
                            status: 8 //Drafting - Check List
                        };

                        this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);
                    })

                    this.showToast("success", "Success!", "Scope of Work finished with successfully");
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.updateTechSpec();

                this.hideLoading();

                this.cancel();
            },

            async showApproveOrDenyFenceType() {
                
                this.fenceTypeNewValueSelected = [];
                if (this.serviceTechSpecRequest.fenceTypeNewValue != null && this.serviceTechSpecRequest.fenceTypeNewValue != undefined && this.serviceTechSpecRequest.fenceTypeNewValue.length > 0) {
                    let listIDFenceType = this.serviceTechSpecRequest.fenceTypeNewValue.split(';');
                    await listIDFenceType.forEach(itemFence => {
                        if (itemFence) {

                            this.fenceTypeNewValueSelected.push({
                                id: parseInt(itemFence),
                                description: this.listFenceType.filter(fen => fen.id == parseInt(itemFence))[0].description
                            })
                        }
                    })
                }

                this.showDialogFenceType = true;
            },

            async approveFenceType() {
                this.fenceTypeSelected = this.fenceTypeNewValueSelected;
                this.serviceTechSpecRequest.fenceTypeNewValue = '0';
                this.serviceTechSpecRequest.fenceTypeNeedsApproval = 0;
                this.showDialogFenceType = false;
            },

            async denyFenceType() {
                this.updateTechSpec();
                this.fenceTypeSelected = null;
                this.serviceTechSpecRequest.fenceTypeNeedsApproval = 0;
                this.showDialogFenceType = false;
            },

            async showApproveOrDenyYesNoAndNotes(title, yesNoValue, notesValue, listPhotos) {

                this.yesNoAndNotesTitle = title;
                this.yesNo = yesNoValue;
                this.notes = notesValue,
                this.dialogPhotos = listPhotos;

                this.showDialogYesNoAndNotes = true;
            },

            async approveYesNoAndNotes() {

                this.serviceTechSpecRequest.approvedOrDeny = 1;

                if (this.yesNoAndNotesTitle == this.titles.dimensionedEquipmentLocation) {
                    this.serviceTechSpecRequest.dimensionedEquipmentLocation = this.serviceTechSpecRequest.dimensionedEquipmentLocationNewValue;
                    this.serviceTechSpecRequest.dimensionedEquipmentLocationNotes = this.serviceTechSpecRequest.dimensionedEquipmentLocationNotesNewValue;

                    this.serviceTechSpecRequest.dimensionedEquipmentLocationNewValue = 0;
                    this.serviceTechSpecRequest.dimensionedEquipmentLocationNotesNewValue = ' ';
                    this.serviceTechSpecRequest.dimensionedEquipmentLocationNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.fenceLocation) {
                    this.serviceTechSpecRequest.fenceLocation = this.serviceTechSpecRequest.fenceLocationNewValue;
                    this.serviceTechSpecRequest.fenceLocationNotes = this.serviceTechSpecRequest.fenceLocationNotesNewValue;

                    this.serviceTechSpecRequest.fenceLocationNewValue = 0;
                    this.serviceTechSpecRequest.fenceLocationNotesNewValue = ' ';
                    this.serviceTechSpecRequest.fenceLocationNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.depthDeepestPointLocation) {
                    this.serviceTechSpecRequest.depthDeepestPointLocation = this.serviceTechSpecRequest.depthDeepestPointLocationNewValue;
                    this.serviceTechSpecRequest.depthDeepestPointLocationNotes = this.serviceTechSpecRequest.depthDeepestPointLocationNotesNewValue;

                    this.serviceTechSpecRequest.depthDeepestPointLocationNewValue = 0;
                    this.serviceTechSpecRequest.depthDeepestPointLocationNotesNewValue = ' ';
                    this.serviceTechSpecRequest.depthDeepestPointLocationNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.lightLocations) {
                    this.serviceTechSpecRequest.lightLocations = this.serviceTechSpecRequest.lightLocationsNewValue;
                    this.serviceTechSpecRequest.lightLocationsNotes = this.serviceTechSpecRequest.lightLocationsNotesNewValue;

                    this.serviceTechSpecRequest.lightLocationsNewValue = 0;
                    this.serviceTechSpecRequest.lightLocationsNotesNewValue = ' ';
                    this.serviceTechSpecRequest.lightLocationsNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas) {
                    this.serviceTechSpecRequest.pergolas = this.serviceTechSpecRequest.pergolasNewValue;
                    this.serviceTechSpecRequest.pergolasNotes = this.serviceTechSpecRequest.pergolasNotesNewValue;

                    this.serviceTechSpecRequest.pergolasNewValue = 0;
                    this.serviceTechSpecRequest.pergolasNotesNewValue = ' ';
                    this.serviceTechSpecRequest.pergolasNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas) {
                    this.serviceTechSpecRequest.pergolas2 = this.serviceTechSpecRequest.pergolas2NewValue;
                    this.serviceTechSpecRequest.pergolas2Notes = this.serviceTechSpecRequest.pergolas2NotesNewValue;

                    this.serviceTechSpecRequest.pergolas2NewValue = 0;
                    this.serviceTechSpecRequest.pergolas2NotesNewValue = ' ';
                    this.serviceTechSpecRequest.pergolas2NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas) {
                    this.serviceTechSpecRequest.pergolas3 = this.serviceTechSpecRequest.pergolas3NewValue;
                    this.serviceTechSpecRequest.pergolas3Notes = this.serviceTechSpecRequest.pergolas3NotesNewValue;

                    this.serviceTechSpecRequest.pergolas3NewValue = 0;
                    this.serviceTechSpecRequest.pergolas3NotesNewValue = ' ';
                    this.serviceTechSpecRequest.pergolas3NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas) {
                    this.serviceTechSpecRequest.pergolas4 = this.serviceTechSpecRequest.pergolas4NewValue;
                    this.serviceTechSpecRequest.pergolas4Notes = this.serviceTechSpecRequest.pergolas4NotesNewValue;

                    this.serviceTechSpecRequest.pergolas4NewValue = 0;
                    this.serviceTechSpecRequest.pergolas4NotesNewValue = ' ';
                    this.serviceTechSpecRequest.pergolas4NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas) {
                    this.serviceTechSpecRequest.pergolas5 = this.serviceTechSpecRequest.pergolas5NewValue;
                    this.serviceTechSpecRequest.pergolas5Notes = this.serviceTechSpecRequest.pergolas5NotesNewValue;

                    this.serviceTechSpecRequest.pergolas5NewValue = 0;
                    this.serviceTechSpecRequest.pergolas5NotesNewValue = ' ';
                    this.serviceTechSpecRequest.pergolas5NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas) {
                    this.serviceTechSpecRequest.pergolas6 = this.serviceTechSpecRequest.pergolas6NewValue;
                    this.serviceTechSpecRequest.pergolas6Notes = this.serviceTechSpecRequest.pergolas6NotesNewValue;

                    this.serviceTechSpecRequest.pergolas6NewValue = 0;
                    this.serviceTechSpecRequest.pergolas6NotesNewValue = ' ';
                    this.serviceTechSpecRequest.pergolas6NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.waterFeatures) {
                    this.serviceTechSpecRequest.waterFeatures = this.serviceTechSpecRequest.waterFeaturesNewValue;
                    this.serviceTechSpecRequest.waterFeaturesNotes = this.serviceTechSpecRequest.waterFeaturesNotesNewValue;

                    this.serviceTechSpecRequest.waterFeaturesNewValue = 0;
                    this.serviceTechSpecRequest.waterFeaturesNotesNewValue = ' ';
                    this.serviceTechSpecRequest.waterFeaturesNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbq = this.serviceTechSpecRequest.bbqNewValue;
                    this.serviceTechSpecRequest.bbqNotes = this.serviceTechSpecRequest.bbqNotesNewValue;

                    this.serviceTechSpecRequest.bbqNewValue = 0;
                    this.serviceTechSpecRequest.bbqNotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbqNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ2 = this.serviceTechSpecRequest.bbQ2NewValue;
                    this.serviceTechSpecRequest.bbQ2Notes = this.serviceTechSpecRequest.bbQ2NotesNewValue;

                    this.serviceTechSpecRequest.bbQ2NewValue = 0;
                    this.serviceTechSpecRequest.bbQ2NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ2NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ3 = this.serviceTechSpecRequest.bbQ3NewValue;
                    this.serviceTechSpecRequest.bbQ3Notes = this.serviceTechSpecRequest.bbQ3NotesNewValue;

                    this.serviceTechSpecRequest.bbQ3NewValue = 0;
                    this.serviceTechSpecRequest.bbQ3NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ3NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ4 = this.serviceTechSpecRequest.bbQ4NewValue;
                    this.serviceTechSpecRequest.bbQ4Notes = this.serviceTechSpecRequest.bbQ4NotesNewValue;

                    this.serviceTechSpecRequest.bbQ4NewValue = 0;
                    this.serviceTechSpecRequest.bbQ4NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ4NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ5 = this.serviceTechSpecRequest.bbQ5NewValue;
                    this.serviceTechSpecRequest.bbQ5Notes = this.serviceTechSpecRequest.bbQ5NotesNewValue;

                    this.serviceTechSpecRequest.bbQ5NewValue = 0;
                    this.serviceTechSpecRequest.bbQ5NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ5NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ6 = this.serviceTechSpecRequest.bbQ6NewValue;
                    this.serviceTechSpecRequest.bbQ6Notes = this.serviceTechSpecRequest.bbQ6NotesNewValue;

                    this.serviceTechSpecRequest.bbQ6NewValue = 0;
                    this.serviceTechSpecRequest.bbQ6NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ6NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ7 = this.serviceTechSpecRequest.bbQ7NewValue;
                    this.serviceTechSpecRequest.bbQ7Notes = this.serviceTechSpecRequest.bbQ7NotesNewValue;

                    this.serviceTechSpecRequest.bbQ7NewValue = 0;
                    this.serviceTechSpecRequest.bbQ7NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ7NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ8 = this.serviceTechSpecRequest.bbQ8NewValue;
                    this.serviceTechSpecRequest.bbQ8Notes = this.serviceTechSpecRequest.bbQ8NotesNewValue;

                    this.serviceTechSpecRequest.bbQ8NewValue = 0;
                    this.serviceTechSpecRequest.bbQ8NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ8NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ9 = this.serviceTechSpecRequest.bbQ9NewValue;
                    this.serviceTechSpecRequest.bbQ9Notes = this.serviceTechSpecRequest.bbQ9NotesNewValue;

                    this.serviceTechSpecRequest.bbQ9NewValue = 0;
                    this.serviceTechSpecRequest.bbQ9NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ9NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ10 = this.serviceTechSpecRequest.bbQ10NewValue;
                    this.serviceTechSpecRequest.bbQ10Notes = this.serviceTechSpecRequest.bbQ10NotesNewValue;

                    this.serviceTechSpecRequest.bbQ10NewValue = 0;
                    this.serviceTechSpecRequest.bbQ10NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ10NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbQ11 = this.serviceTechSpecRequest.bbQ11NewValue;
                    this.serviceTechSpecRequest.bbQ11Notes = this.serviceTechSpecRequest.bbQ11NotesNewValue;

                    this.serviceTechSpecRequest.bbQ11NewValue = 0;
                    this.serviceTechSpecRequest.bbQ11NotesNewValue = ' ';
                    this.serviceTechSpecRequest.bbQ11NeedsApproval = 0;
                }
                
                this.showDialogYesNoAndNotes = false;
            },

            async denyYesNoAndNotes() {
                await this.updateTechSpec();

                this.serviceTechSpecRequest.approvedOrDeny = 2;

                if (this.yesNoAndNotesTitle == this.titles.dimensionedEquipmentLocation) {
                    this.serviceTechSpecRequest.dimensionedEquipmentLocationNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.fenceLocation) {
                    this.serviceTechSpecRequest.fenceLocationNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.depthDeepestPointLocation) {
                    this.serviceTechSpecRequest.depthDeepestPointLocationNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.lightLocations) {
                    this.serviceTechSpecRequest.lightLocationsNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas) {
                    this.serviceTechSpecRequest.pergolasNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas2) {
                    this.serviceTechSpecRequest.pergolas2NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas3) {
                    this.serviceTechSpecRequest.pergolas3NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas4) {
                    this.serviceTechSpecRequest.pergolas4NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas5) {
                    this.serviceTechSpecRequest.pergolas5NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.pergolas6) {
                    this.serviceTechSpecRequest.pergolas6NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.waterFeatures) {
                    this.serviceTechSpecRequest.waterFeaturesNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq) {
                    this.serviceTechSpecRequest.bbqNeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq2) {
                    this.serviceTechSpecRequest.bbq2NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq3) {
                    this.serviceTechSpecRequest.bbq3NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq4) {
                    this.serviceTechSpecRequest.bbq4NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq5) {
                    this.serviceTechSpecRequest.bbq5NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq6) {
                    this.serviceTechSpecRequest.bbq6NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq7) {
                    this.serviceTechSpecRequest.bbq7NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq8) {
                    this.serviceTechSpecRequest.bbq8NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq9) {
                    this.serviceTechSpecRequest.bbq9NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq10) {
                    this.serviceTechSpecRequest.bbq10NeedsApproval = 0;
                }

                if (this.yesNoAndNotesTitle == this.titles.bbq11) {
                    this.serviceTechSpecRequest.bbq11NeedsApproval = 0;
                }

                this.showDialogYesNoAndNotes = false;
            },
        }
    };
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

    .v-stepper__content {
        padding: 0px !important;
    }

    .titleFont {
        color: var(--color__main); 
        font-weight: bold;
    }

    .rowPaddingTop {
        padding-top: 10px !important;
    }

    .colPaddingTop {
        padding-top: 20px !important;
    }

    .btnAlert {
        width: 170px;
        background-color: var(--color__alert) !important; 
        color: #ffffff !important;
    }
</style>